<template>
  <div class="create-project flex-column w-100 h-100">
    <NewProjectHeader/>
    <WizardForm
      class="create-project__wizard-form"
      :config="wizardFormConfig"
      @set-active-form="setActiveForm"
      @on-complete="onComplete">
      <template #custom-content>
        <el-divider class="m-0 p-0"/>
      </template>
    </WizardForm>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import WizardForm from '@/core/components/common/forms/WizardForm.vue';
import NewProjectHeader from '@/modules/project-dashboard-children/components/NewProjectHeader.vue';

export default defineComponent({
  components: {
    WizardForm,
    NewProjectHeader
  },

  data() {
    return {
      wizardFormConfig: [
        {
          name: 'property',
          title: 'Property',
          component: 'SelectProperty',
          hideArrow: true,
        },
        {
          name: 'projectType',
          title: 'Project Type',
          component: 'ProjectType',
          dependent: 'property',
          hideArrow: true,
        },
        {
          name: 'timeline',
          title: 'Timelines',
          component: 'Timeline',
          dependent: 'projectType',
          hideArrow: true,
        },
        {
          name: 'jobSpecifics',
          title: 'Job Specifics',
          component: 'JobSpecifics',
          dependent: 'timeline',
          hideArrow: true,
        },
        {
          name: 'projectAttachments',
          title: 'Documents',
          component: 'ProjectAttachments',
          dependent: 'jobSpecifics',
          hideArrow: true,
        },
      ],
      activeForm: null,
    };
  },

  methods: {
    setActiveForm(form) {
      this.activeForm = form;
    },
  },
});
</script>
<style lang="scss" scoped>
.create-project {
  &__wizard-form {
    height: calc(100% - 10rem);
  }
}
</style>

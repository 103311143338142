<template>
  <div class="start-project-intro flex-column ai-fs gap-2">
    <div class="start-project-intro__wrapper flex-column">
      <Typography variant="h3" textWeight="400" class="mb-5">
        Start your next project.
      </Typography>
      <div class="flex-column">
        <Icon iconWidth="280px" iconHeight="140px" iconName="kickstart.svg" class="mb-5" />
        <Typography textSize="14px" textWeight="500" class="mb-24">
            Kickstart your next project at lightning speed.
        </Typography>
      </div>
    </div>
    <div class="start-project-intro__item-wrapper flex-column gap-half">
      <div class="flex-row gap-half">
        <Icon
          iconWidth="24px"
          iconHeight="24px"
          iconName="tick-icon-check.svg"
          class="mb-5 icon-style"
        />
        <Typography textSize="14px" textWeight="400">
          <Typography variant="p" textSize="14px">
            Dispute protection
          </Typography>
          as standard
        </Typography>
      </div>
      <div class="flex-row gap-half">
        <Icon
          iconWidth="24px"
          iconHeight="24px"
          iconName="tick-icon-check.svg"
          class="mb-5 icon-style"
        />
        <Typography textSize="14px" textWeight="400">
          Deposits secured
          <Typography variant="p" textSize="14px"> in Escrow </Typography>
        </Typography>
      </div>
      <div class="flex-row gap-half">
        <Icon
          iconWidth="24px"
          iconHeight="24px"
          iconName="tick-icon-check.svg"
          class="mb-5 icon-style"
        />
        <Typography textSize="14px" textWeight="400">
          Verified trade contractors
          <Typography variant="p" textSize="14px">
            in your local area
          </Typography>
        </Typography>
      </div>
    </div>
    <Button
      class="w-100"
      buttonText="start new project"
      :isActive="true"
      activeColor="#4B4BFF"
      activeFontColor="#FFFFFF"
      @handle-click="onStartProject"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Button,
    Icon,
    Typography
  },

  emits: ['on-start-new-project'],

  methods: {
    onStartProject() {
      this.$emit('on-start-new-project');
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/variables/layout" as *;

.start-project-intro {
  width: 100%;
  height: 100%;
  max-width: 400px;
}

@include media-xs() {
  .start-project-intro {
    &__wrapper {
      gap: 2rem;
    }

    &__item-wrapper {
      gap: 1rem;
    }
  }
}
</style>

<template>
    <section class="new-project-header__wrapper flex-column">
      <div class="flex-row ai-c jc-sb">
        <div class="flex-row ai-c gap-half cursor-pointer">
          <el-icon color="#48597B" size="14"><ArrowLeftBold/></el-icon>
          <Typography variant="p" textColor="#48597B">Back</Typography>
        </div>
      </div>
    <div class="flex-row ai-c jc-sb">
      <div class="flex-row ai-c gap-half cursor-pointer">
        <span class="new-project-header__step-counter flex-row ai-c jc-c">
          <Typography variant="custom" textSize="12px" textWeight="400" textColor="#FFF">1</Typography>
        </span>
        <Typography variant="h6" textColor="#264FD5">Property</Typography>
       </div>
    </div>
    <div class="flex-row ai-c jc-sb">
      <div class="flex-row ai-c gap-half cursor-pointer">
        <Typography variant="h4" textWeight="400">Please select the property / properties this project is for</Typography>
      </div>
    </div>
</section>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Typography,
    ArrowLeftBold
  }
});
</script>
<style lang="scss" scoped>
.new-project-header {
   &__step-counter {
    border-radius: 100px;
    background: #264FD5;
    min-width: 18px;
    min-height: 18px;
    max-height: 18px;
  }
}
</style>

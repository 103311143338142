import { defineComponent } from 'vue';
import CreateProject from '@/modules/project-dashboard-children/components/screens/CreateProject.vue';
import StartProjectIntro from '@/modules/project-dashboard-children/components/screens/StartProjectIntro.vue';
const DEFAULT_SCREEN = 'default';
const CREATE_PROJECT_SCREEN = 'create-project';
export default defineComponent({
    name: 'NewProjectPage',
    components: {
        StartProjectIntro,
        CreateProject
    },
    data() {
        return {
            DEFAULT_SCREEN,
            CREATE_PROJECT_SCREEN,
            currentScreen: DEFAULT_SCREEN
        };
    },
    methods: {
        onStartNewProject() {
            this.setScreen(CREATE_PROJECT_SCREEN);
        },
        setScreen(screen) {
            this.currentScreen = screen;
        }
    },
});
